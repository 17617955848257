<script lang="ts" setup>
import { UIDatepicker, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { computed, onMounted, PropType, ref } from 'vue'
import {
  DateFieldElementOptions,
  ICustomFieldsLinkageForm,
  IElement,
  IRecipient,
  MovableComponentEvents,
  RecipientEntityTypeEnum,
} from '../../types'

import { MovableComponentWrapper } from '../MovableComponentWrapper'
import { CommonTooltip } from '../Tooltip'
import {
  getSignatureElementColorStyles,
  validateDate,
  convertToUTC,
  convertUtcToUNIX,
  generateCustomLinkItemPayload,
} from '../../utils'
import { DEFAULT_DATE_FORMAT, ELEMENTS_META } from '../../const'
import { CustomFieldTriggerLink } from '../CustomSelectTrigger'

const props = defineProps({
  element: {
    required: true,
    type: Object as PropType<IElement<DateFieldElementOptions>>,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  snapContainer: {
    type: String,
  },
  storeMap: {
    type: Object,
    required: true,
  },
  boundContainer: {
    type: String,
  },
  compositionMap: {
    type: Object,
    required: true,
  },
})
const enableTooltip = ref(true)
const isActiveElement = computed(() => props.isActive)
const builderStore = props.storeMap.useBuilderStore()
const emits = defineEmits([MovableComponentEvents.ON_ACTIVE])
const { updateStyles, updateOption } = props.compositionMap.useElementOption()
const store = props.storeMap.useAppStore()

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id }) => id === props.element?.component.options.recipient
    ) || ({} as IRecipient)
  )
})
const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return firstName ? `${firstName} ${lastName || ''}` : ''
})

const onDrag = (styles: any) => {
  updateStyles('position', styles, props.element?.id)
  updateOption('isGhost', true, props.element?.id)
}
const onDragUpdate = (value: boolean) => {
  enableTooltip.value = !value
}
const onScale = ({ scaleX, scaleY }: { scaleX: number; scaleY: number }) => {
  updateStyles('scale', { scaleX, scaleY }, props.element?.id)
}

const onResize = ({ height, width }: { height: number; width: number }) => {
  updateStyles('dimensions', { height, width }, props.element?.id)
}

const onActive = (e: any, isActive: boolean) => {
  emits(MovableComponentEvents.ON_ACTIVE, e, isActive)
}
function del(e: any) {
  e.stopPropagation()
  builderStore.deleteElement(props?.element?.id as string)
  builderStore.syncRecipient()
  builderStore.syncCustomFieldsForLinkage()
}

onMounted(() => {
  builderStore.syncRecipient()
})
function clone(e: MouseEvent) {
  e.stopImmediatePropagation()
  builderStore.cloneElement(props?.element?.id as string)
}

const onChange = (value: string) => {
  updateOption(ELEMENTS_META.TEXT, convertToUTC(value), props.element?.id)
}
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  return (
    {
      height: dimensionsObj?.height + 'px',
      width: dimensionsObj?.width + 'px',
    } || { height: 'auto', width: 'auto' }
  )
})
const colorStyles = computed(() => {
  const dimensionsObj = dimensions.value

  return {
    ...styles.value,
    marginTop: '-1px',
    border: `1px solid ${styles.value.borderColor} !important`,
    'box-shadow': 'none !important',
    ...(dimensionsObj
      ? { height: `${dimensionsObj.height}`, width: `${dimensionsObj.width}` }
      : {}),
  }
})
const position = computed(() => {
  const pos = props.element?.responsiveStyles.large.position
  return pos || { top: 0, left: 0, bottom: 0 }
})
const scale = computed(() => {
  const scaleObj = props.element?.responsiveStyles.large.scale
  return scaleObj || { scaleX: 1, scaleY: 1 }
})

const align = computed(() => props.element?.responsiveStyles.large.align)

const docVariableList = computed(() => store.document?.variables || [])

const availableDocumentVariablesList = computed(() => {
  const docDefaultVariable = docVariableList.value.map(vb => vb.fieldKey)
  builderStore.getAllLinkedDocumentVariables().forEach(vb => {
    if (!docDefaultVariable.includes(vb.fieldKey)) {
      docDefaultVariable.push(vb.fieldKey)
    }
  })
  return docDefaultVariable || []
})

const isContactRecipient = computed(
  () =>
    props.element?.component.options.recipient &&
    props.element?.component.options.entityName ===
      RecipientEntityTypeEnum.CONTACTS
)

const contactCustomValueMapping = computed(() => {
  return builderStore.getContactCustomValueLinkMapping()
})

const onSelectCustomValueLinkage = (formValue: ICustomFieldsLinkageForm) => {
  const payload = generateCustomLinkItemPayload(formValue)
  builderStore.updateCustomValueLinkage(
    formValue.elementId,
    formValue.elementType,
    payload
  )
}
const onDragEnd = (options: any) => {
  builderStore.moveFloatingElement(props.element?.id as string, options)
}
</script>

<template>
  <MovableComponentWrapper
    :key="`moveable-element-${props.element?.id}`"
    :id="(props.element?.id as string)"
    @on-drag="onDrag"
    @on-scale="onScale"
    @on-active="onActive"
    @on-resize="onResize"
    @on-drag-update="onDragUpdate"
    @on-drag-end="onDragEnd"
    :isActiveElement="isActiveElement"
    :isEditable="builderStore.isDocumentEditable"
    :position="position"
    :scale="scale"
    :align="align"
    :dimensions="props.element?.responsiveStyles.large.dimensions"
    :isGhost="props.element?.component.options.isGhost"
    :snap-container="props.snapContainer"
    :bound-container="props.boundContainer"
    :isDragElement="props.element?.component.isDraggable"
    :resizable="true"
    :keep-ratio="false"
    :render-directions="['se']"
    :min-height="26"
    :max-height="26"
    :min-width="15"
    :colorOverride="styles.placeholderColor"
    :inactiveColorOverride="styles.borderColor"
    :with-double-click="false"
  >
    <template #component>
      <span v-if="element?.component.options.required" class="option--required"
        >*</span
      >
      <div class="el-wrapper date-field-el h-[inherit]">
        <UIDatepicker
          :class="{
            'hidden-suffix': props.element?.component.options.text,
          }"
          :key="
            props.element?.id +
            `_` +
            props.element?.component.options.dateFormat
          "
          :is-date-disabled="(timestamp: number) => builderStore.isDocumentEditable ? validateDate(props.element?.component.options.availableDates, timestamp) : false"
          class="date-field rounded-sm !text-sm !cursor-pointer"
          :style="!props.element?.component.options.text ? colorStyles : ''"
          :id="`floating_date_field-${props.element?.id}`"
          :placeholder="props.element?.component.options.placeholder"
          type="date"
          @update:value="onChange"
          size="medium"
          :value="
            props.element?.component.options.text
              ? convertUtcToUNIX(props.element?.component.options.text)
              : null
          "
          :format="
            props.element?.component.options.dateFormat || DEFAULT_DATE_FORMAT
          "
          :actions="['now', 'clear']"
          :placement="'top-end'"
        />
      </div>
    </template>
    <template #tooltip v-if="recipientName">
      <UITextSmRegular
        class="capitalize font-semibold py-1 px-4 text-xs"
        :style="{ color: styles.placeholderColor }"
        >{{ recipientName }}</UITextSmRegular
      >
    </template>
    <template #popup-options>
      <CommonTooltip @on-clone="clone" @on-delete="del">
        <template #extra>
          <CustomFieldTriggerLink
            v-if="
              isActiveElement &&
              builderStore.isDocumentEditable &&
              isContactRecipient
            "
            :element="props.element"
            :popover-id="'popover-filed-link-' + props.element?.id"
            :label="$t('common.linkDateField')"
            :available-document-variables="availableDocumentVariablesList"
            @custom-variable-added="onSelectCustomValueLinkage"
            :contactCustomValueMapping="contactCustomValueMapping"
          />
        </template>
      </CommonTooltip>
    </template>
  </MovableComponentWrapper>
</template>

<style lang="scss">
.date-field {
  .n-input {
    --n-color-focus: v-bind('styles.backgroundColor') !important;
    --n-height: v-bind('dimensions.height') !important;
    --n-border-hover: 1px solid transparent !important;
    --n-border-pressed: 1px solid transparent !important;
    --n-border-focus: 1px solid transparent !important;
    --n-caret-color: 1px solid transparent !important;
    --n-box-shadow-focus: 1px solid transparent !important;
    --n-loading-color: 1px solid transparent !important;

    --n-border: 1px solid transparent !important;
    --n-color: transparent !important;
    box-shadow: none !important;
    border-radius: 2px;
    .n-input-wrapper {
      padding: 0 !important;
      .n-input__suffix {
        // display: none !important;
        .n-base-icon {
          color: rgb(141, 141, 141) !important;
        }
      }
      .n-input__input-el {
        padding: 0px 2px !important;
        font-weight: 600 !important;
      }
      .n-input__placeholder {
        padding: 0px 2px !important;
        color: v-bind('styles.placeholderColor') !important;
      }
    }
  }
}

.option--required {
  position: absolute;
  right: -7px;
  top: -17px;
  color: red;
  font-size: 22px;
  font-weight: 700;
}
.date-field {
  .n-input__input {
    font-size: 14px !important;
  }
}
</style>
