<script setup lang="ts">
import { UIButton } from '@gohighlevel/ghl-ui'
import { PropType } from 'vue'
import { IRecipient, APP_STORE_META } from '@gohighlevel/ghl-proposals-common'
import { Trash01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { useAppStore } from '@/store/app'
import { useElementOption } from '@/composable/elementOptions'
import { useBuilderStore } from '@/store/builder'
import UserAvatar from '@/components/common/UserAvatar.vue'

const { updateDocumentOption } = useElementOption()
const store = useBuilderStore()
const appStore = useAppStore()

const props = defineProps({
  recipient: { type: Object as PropType<IRecipient> },
  delete: { type: Boolean, default: false },
})

const deleteRecipient = (recipientId: string) => {
  if (recipientId) {
    const recipients = appStore.document?.recipients?.filter((el: any) => {
      return el.id !== recipientId
    })
    updateDocumentOption(APP_STORE_META.RECIPIENTS, recipients)
  }
}
</script>

<template>
  <div class="flex flex-col relative" v-if="recipient?.id">
    <UserAvatar :recipient="recipient" :show-recipient-tag="true" />
    <div
      class="flex-none absolute right-0 top-3"
      v-if="
        props.delete &&
        recipient.entityName === 'contacts' &&
        store.isDocumentEditable
      "
    >
      <UIButton
        :id="`btn_custom_${recipient?.id}`"
        @click="deleteRecipient(recipient?.id)"
        :circle="true"
        :quaternary="true"
      >
        <Trash01Icon class="w-4 h-4 cursor-pointer"
      /></UIButton>
    </div>
  </div>
</template>
