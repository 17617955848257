<script setup lang="ts">
import {
  IElement,
  IRecipient,
  RecipientEntityTypeEnum,
  APP_STORE_META,
  ELEMENTS_META,
  useTypography,
  DateFieldElementOptions,
  dateFieldDateValidationOptions,
  dateFormatOptions,
  DEFAULT_DATE_FORMAT,
  getMarginProperties,
  getPaddingProperties,
  getRecipientParamsFromContact,
  useCustomFieldsForLinkage,
  generateCustomLinkItemPayload,
  ICustomFieldsLinkageForm,
  AddCustomFieldModal,
  CustomValueLinkageItems,
} from '@gohighlevel/ghl-proposals-common'
import {
  UIFormItem,
  UIInput,
  UICheckbox,
  UISelect,
  UITextXsRegular,
  UIDivider,
  UIButton,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, onMounted, ref, watch } from 'vue'
import { useElementOption } from '../../../composable/elementOptions.js'
import { useAppStore } from '@/store/app'
import RecipientCard from '@/components/drawer/DrawerRecipients/RecipientCard.vue'
import { useBuilderStore } from '@/store/builder'
import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash'

const { t } = useI18n()
const props = defineProps({
  element: Object as PropType<IElement<DateFieldElementOptions>>,
})

const { updateOption } = useElementOption()
const { updateDocumentOption } = useElementOption()
const { syncRecipient, syncCustomFieldsForLinkage } = useBuilderStore()
const { toCapitalize } = useTypography()
const builderStore = useBuilderStore()
const { dateCustomFields } = useCustomFieldsForLinkage()
const showLinkFieldModal = ref(false)
const cvlOperationType = ref('add')
const cvlData = ref()
const dateCustomFieldOptions = computed(() => dateCustomFields.value)
const recipients = computed(() => appStore.document?.recipients)
const appStore = useAppStore()
const recipient = ref()
const selectValue = ref()
const margin = ref(getMarginProperties(props.element))
const padding = ref(getPaddingProperties(props.element))
const customValueLinkage = computed(
  () => builderStore.getCustomValueLinkage(props?.element?.id) || []
)
const docVariableList = computed(() => appStore.document?.variables || [])

onMounted(() => {
  syncRecipientList()
})

const syncRecipientList = () => {
  syncRecipient()
  fetchRecipientData()
  getSelectValue()
  syncCustomFieldsForLinkage()
}

const signedByOptions = computed(() => {
  let allRecipients = recipients.value
  const currentUser = getCurrantLoggedInUserDetails()
  const currentUserExist = allRecipients.find(elem => {
    return (
      elem.id === currentUser.id &&
      elem.entityName === RecipientEntityTypeEnum.USERS
    )
  })
  if (!currentUserExist) {
    allRecipients = [...allRecipients, currentUser]
  }
  const recipientList =
    allRecipients.map((contact: IRecipient) => ({
      ...contact,
      label: toCapitalize(
        appStore.userId === contact.id
          ? `${contact?.contactName || ''} ${t('common.you')}`
          : contact?.contactName || `${contact.firstName} ${contact.lastName}`
      ).trim(),
      value: `${contact.id}_${contact.entityName}`,
    })) || []
  return recipientList
})

const getSelectValue = () => {
  if (
    props.element?.component.options[
      ELEMENTS_META.RECIPIENT as keyof DateFieldElementOptions
    ] &&
    props.element?.component.options[
      ELEMENTS_META.ENTITY_NAME as keyof DateFieldElementOptions
    ]
  ) {
    selectValue.value = `${
      props.element?.component.options[
        ELEMENTS_META.RECIPIENT as keyof DateFieldElementOptions
      ]
    }_${
      props.element?.component.options[
        ELEMENTS_META.ENTITY_NAME as keyof DateFieldElementOptions
      ]
    }`
  }
}

const getCurrantLoggedInUserDetails = () => {
  const contactDetails = getRecipientParamsFromContact(
    appStore.userData,
    RecipientEntityTypeEnum.USERS,
    false
  )

  return {
    ...contactDetails,
    contactName: `${contactDetails.firstName} ${contactDetails.lastName}`,
  }
}

const fetchRecipientData = (recipientId?: string, entityName?: string) => {
  const assignedRecipient = recipients.value.find((elem: IRecipient) => {
    if (recipientId && entityName) {
      return elem.id === recipientId && elem.entityName === entityName
    } else {
      return (
        elem.id ===
          props.element?.component.options[
            ELEMENTS_META.RECIPIENT as keyof DateFieldElementOptions
          ] &&
        elem.entityName ===
          props.element?.component.options[
            ELEMENTS_META.ENTITY_NAME as keyof DateFieldElementOptions
          ]
      )
    }
  })
  if (assignedRecipient) {
    recipient.value = assignedRecipient
  }
}

const assignRecipientToTextField = (val: string) => {
  const recipientData = val.split('_')
  if (recipientData.length === 2) {
    const recipientId = recipientData[0]
    const entityName = recipientData[1]
    if (
      entityName === RecipientEntityTypeEnum.USERS &&
      appStore.userId === recipientId
    ) {
      const duplicateUsers = recipients.value.find((el: any) => {
        return el.id === appStore.userId && el.entityName === entityName
      })
      if (!duplicateUsers) {
        const contactDetails = getCurrantLoggedInUserDetails()
        const allRecipients = [...recipients.value, contactDetails]
        updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
      }
    }
    fetchRecipientData(recipientId, entityName)
    updateOption(ELEMENTS_META.RECIPIENT, recipientId)
    updateOption(ELEMENTS_META.ENTITY_NAME, entityName)
  }

  syncRecipientList()
}

watch(props, newValue => {
  if (newValue) {
    syncRecipientList()
  }
  if (props.element) {
    margin.value = getMarginProperties(props.element)
    padding.value = getPaddingProperties(props.element)
  }
})

const isContactRecipient = computed(
  () =>
    props.element?.component.options.recipient &&
    props.element?.component.options.entityName ===
      RecipientEntityTypeEnum.CONTACTS
)

const contactCustomValueMapping = computed(() => {
  return builderStore.getContactCustomValueLinkMapping()
})

const toggleModal = () => {
  showLinkFieldModal.value = !showLinkFieldModal.value
}

const availableDocumentVariablesList = computed(() => {
  const docDefaultVariable = docVariableList.value.map(vb => vb.fieldKey)
  builderStore.getAllLinkedDocumentVariables().forEach(vb => {
    if (!docDefaultVariable.includes(vb.fieldKey)) {
      docDefaultVariable.push(vb.fieldKey)
    }
  })
  return docDefaultVariable || []
})

const customVariableAdded = (formValue: ICustomFieldsLinkageForm) => {
  const operationType = formValue?.operationType
  const payload = generateCustomLinkItemPayload(formValue)
  if (operationType === 'add') {
    builderStore.updateCustomValueLinkage(
      formValue.elementId,
      formValue.elementType,
      payload
    )
  }
  if (operationType === 'edit') {
    builderStore.updateCustomValueLinkageItem(
      formValue.elementId,
      payload,
      formValue?.operationValue?.data
    )
  }
  debounce(() => (showLinkFieldModal.value = false), 400)()
}

const customVariableDeleted = (event: {
  type: string
  data: any
  element: any
}) => {
  const elementId = event.element.id
  const filedKey = event.data.fieldKey
  builderStore.deleteCustomValueLinkage(elementId, filedKey)
}

const addMoreCustomFields = () => {
  cvlOperationType.value = 'add'
  showLinkFieldModal.value = true
}

const customVariableEdit = (event: {
  type: string
  data: any
  element: any
}) => {
  cvlData.value = event
  cvlOperationType.value = 'edit'
  showLinkFieldModal.value = true
}
</script>

<template>
  <AddCustomFieldModal
    :operationType="cvlOperationType"
    :operationValue="cvlData"
    :element="element"
    :show="showLinkFieldModal"
    @on-cancel="toggleModal"
    :availableDocumentVariables="availableDocumentVariablesList"
    @on-success="customVariableAdded"
    :menuOptions="dateCustomFieldOptions"
    :available-custom-value-links="customValueLinkage"
    :contactCustomValueMapping="contactCustomValueMapping"
  />
  <div class="hl-proposal-sidebar-content hl-proposal-textfield-properties">
    <div class="mb-4">
      <UIFormItem :label="$t('common.toBeFilledBy')">
        <UISelect
          :id="`signature-text`"
          :placeholder="$t('common.assignRecipientToTextField')"
          :options="signedByOptions"
          type="avatar"
          :value="selectValue"
          @update:value="(val: any) => assignRecipientToTextField(val)"
        />
      </UIFormItem>
      <div>
        <RecipientCard :recipient="recipient" />
      </div>
    </div>
    <UITextXsRegular class="font-medium text-gray-400">
      {{ $t('common.options') }}</UITextXsRegular
    >
    <UIDivider />
    <UIFormItem :label="$t('common.placeholder')">
      <UIInput
        :id="`${element?.id}-placeholder`"
        :model-value="element?.component.options.placeholder"
        @update:model-value="
          val => updateOption(ELEMENTS_META.PLACEHOLDER, val)
        "
      />
    </UIFormItem>

    <!-- <UIFormItem label="Field ID">
      <UIInput
        :id="`${element?.id}-field_id`"
        :model-value="element?.component.options.fieldId"
        @update:model-value="val => updateOption(ELEMENTS_META.FIELD_ID, val)"
      />
    </UIFormItem> -->
    <UIFormItem label="Date Formats">
      <UISelect
        :defaultValue="DEFAULT_DATE_FORMAT"
        :options="dateFormatOptions"
        :id="`${element?.id}-date-formats`"
        :value="element?.component.options.dateFormat"
        @update:value="val => updateOption(ELEMENTS_META.DATE_FORMAT, val)"
      />
    </UIFormItem>
    <UIFormItem label="Available Dates">
      <UISelect
        :options="dateFieldDateValidationOptions"
        :id="`${element?.id}-available-dates`"
        :value="element?.component.options.availableDates"
        @update:value="val => updateOption(ELEMENTS_META.AVAILABLE_DATES, val)"
      />
    </UIFormItem>
    <UICheckbox
      :id="`${element?.id}-required-checkbox`"
      :disabled="false"
      :checked="element?.component.options.required"
      @update:checked="(val: boolean) => {
        updateOption(ELEMENTS_META.REQUIRED, val, element?.id)
      }"
    >
      {{ $t('common.required') }}
    </UICheckbox>

    <div v-if="isContactRecipient">
      <UITextXsRegular class="font-medium text-gray-400 mt-6 uppercase">
        {{ $t('common.linkedFields') }}</UITextXsRegular
      >
      <UIDivider />

      <CustomValueLinkageItems
        :customValueLinkage="customValueLinkage"
        :element="element"
        @custom-value-linkage-delete="customVariableDeleted"
        @custom-value-linkage-edit="customVariableEdit"
      />

      <UIDivider v-if="customValueLinkage && customValueLinkage.length > 0" />

      <div class="mb-4">
        <UIButton
          class="w-full"
          id="add-more-linked-fields-cta-button"
          @click="addMoreCustomFields"
          type="tertiary"
        >
          {{
            customValueLinkage && customValueLinkage.length > 0
              ? $t('common.addMoreCustomFields')
              : $t('common.addCustomFields')
          }}
        </UIButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.n-avatar__text {
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.hl-proposal-textfield-properties {
  .n-divider.n-divider--no-title {
    margin: 10px 0px;
  }
}
</style>
