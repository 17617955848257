import {
  APP_STORE_META,
  AppStore,
  DOCUMENT_LINKED_VARIABLE,
  DocumentStatus,
  ELEMENTS_LOOKUP,
  ELEMENTS_META,
  ElementTypes,
  GROUP_LOOKUP,
  GroupFields,
  GroupFieldsOptions,
  ICustomFieldsLink,
  ICustomFieldsLinkItem,
  IElement,
  IPage,
  IRecipient,
  IResponsiveStylesOptions,
  IUpdateElementOption,
  LineItem,
  ObjectForLinkedField,
  PositionTypeEnum,
  PriceTypeEnum,
  ProductListElementOptions,
  RecipientEntityTypeEnum,
  UpdateSignatureParams,
  assignableFields,
  fillableFields,
  flatChildrens,
  removeFillableElementsWhichIsPartOfGroup,
  removeUserFromRecipientIfElementIsNotAssigned,
  updateRecipientRolesIfElementIsAssigned,
  usePosition,
} from '@gohighlevel/ghl-proposals-common'
import {
  createElement,
  generateElementId,
  useTextFieldIdCounter,
} from '@/helper/element-factory'

import { Editor } from '@tiptap/vue-3'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'
import { useAppStore } from './app'
import { useElementOption } from '@/composable/elementOptions'

const { getNewPositionForElement } = usePosition()
const { getNextCount } = useTextFieldIdCounter()

export const useBuilderStore = defineStore('builderStore', {
  state: (): AppStore => ({
    activeElementId: '',
    groups: [] as GroupFields<GroupFieldsOptions>[],
    activeGroup: {} as GroupFields<GroupFieldsOptions>,
    editor: undefined,
    pages: [],
    hover: false,
    focusedElement: {} as IElement,
    undoRedoStack: [],
    undoRedoStackPointer: 0,
    moveElementDragState: false,
    status: DocumentStatus.DRAFT,
    flatCustomValues: {},
    customValueLinkage: {} as Record<string, ICustomFieldsLink>,
  }),
  getters: {
    isDocumentEditable: state => {
      return state.status === DocumentStatus.DRAFT
    },
    activeElement: state => {
      const page = state.pages.find(page => {
        return page.id === state.activeElementId
      })
      if (page) return page
      const activeElement = flatChildrens(state.pages).find(
        (element: IElement) => element?.id === state.activeElementId
      )
      return activeElement
    },
    hasLineItems: state => {
      return flatChildrens(state.pages).find(
        (element: IElement) => element.type === ELEMENTS_LOOKUP.PRODUCT_LIST
      )?.id
        ? true
        : false
    },
    totalLineItemsLength: state => {
      return flatChildrens(state.pages)
        .filter(
          child =>
            child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
            (child as IElement<ProductListElementOptions>).component.options
              .lineItems
        )
        .map(
          child =>
            (child as IElement<ProductListElementOptions>).component.options
              .lineItems.length
        )
        .reduce((acc, count) => acc + count, 0)
    },
    getAllLineItems: state => {
      return flatChildrens(state.pages)
        .filter(
          child =>
            child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
            (child as IElement<ProductListElementOptions>).component.options
              .lineItems
        )
        .reduce((acc, elem) => {
          acc.push(...elem.component.options.lineItems)
          return acc
        }, [] as LineItem[])
    },
  },
  actions: {
    setStatus(status: DocumentStatus) {
      this.status = status
    },
    findElementInChildren(
      id: string,
      children: any[],
      parent: any,
      parentType: ElementTypes
    ): {
      element: IElement
      parent: IElement
      parentType: ElementTypes
    } | null {
      for (const child of children) {
        if (child && child.id === id) {
          return {
            element: child,
            parent,
            parentType,
          }
        }

        if (child?.children && child?.children?.length > 0) {
          const found = this.findElementInChildren(
            id,
            child.children,
            child,
            child.type
          )
          if (found) return found
        }
      }
      return null
    },
    getElementById(id: string) {
      for (const page of this.pages) {
        if (page.id === id) {
          return {
            element: page,
            parent: null,
            parentType: null,
          }
        }

        const found = this.findElementInChildren(
          id,
          page.children,
          page,
          ELEMENTS_LOOKUP.PAGE
        )
        if (found) return found
      }

      return null
    },
    getPageIdByElementId(elementId: string) {
      return this.pages.find(page =>
        page.children?.some(element => {
          if (element.type === ELEMENTS_LOOKUP.ROW) {
            return element.children?.some(child =>
              child.children.some(c => c.id === elementId)
            )
          }
          return element.id === elementId
        })
      )?.id
    },
    createRedoStack(skipUnsavedChanges?: boolean) {
      if (!this.isDocumentEditable) return
      this.undoRedoStack.push(cloneDeep(this.pages))
      this.undoRedoStackPointer = this.undoRedoStack.length - 1
      const appStore = useAppStore()
      if (!skipUnsavedChanges) appStore.setUnsavedChanges(true)
    },
    undo() {
      if (!this.isDocumentEditable) return
      if (this.undoRedoStackPointer > 0) {
        this.undoRedoStackPointer--
        const page = cloneDeep(this.undoRedoStack[this.undoRedoStackPointer])
        this.pages = page
      }
    },
    redo() {
      if (!this.isDocumentEditable) return
      if (this.undoRedoStackPointer < this.undoRedoStack.length - 1) {
        this.undoRedoStackPointer++
        const page = cloneDeep(this.undoRedoStack[this.undoRedoStackPointer])
        this.pages = page
      }
    },
    setPages(pages: IPage[]) {
      this.pages = pages
      if (!this.isDocumentEditable) return
      this.createRedoStack(true)
    },
    setActiveGroup(groupId: string | null) {
      if (!this.isDocumentEditable) return
      if (!groupId) {
        this.activeGroup = null
      }
      this.activeGroup = this.groups.find(g => g.id === groupId)
      this.createRedoStack(true)
    },
    setGroups(groups: GroupFields<GroupFieldsOptions>[]) {
      this.groups = groups
      this.syncGroup()
      if (!this.isDocumentEditable) return
      this.createRedoStack(true)
    },
    updateGroup(group: GroupFields<GroupFieldsOptions>) {
      if (!this.isDocumentEditable) return
      this.groups = [...this.groups, group]
      this.createRedoStack(true)
    },
    deleteGroupAndGroupElements(id: string) {
      if (!this.isDocumentEditable) return
      const group = this.groups.find(g => g.id === id)
      group?.children.forEach(({ elementId }) => this.deleteElement(elementId))
      this.groups = this.groups.filter(g => g.id !== id)
      this.createRedoStack(true)
    },
    deleteGroup(id: string) {
      if (!this.isDocumentEditable) return
      this.groups = this.groups.filter(g => g.id !== id)
      this.createRedoStack(true)
    },
    updateCustomValueLinkage(
      elementId: string,
      type: string,
      customLinkItem: ICustomFieldsLinkItem
    ) {
      if (!this.isDocumentEditable) return
      if (this.customValueLinkage[elementId]) {
        // check the type and push the customLinkItem to the existing customFields array
        const cf = this.customValueLinkage[elementId].customFields
        if (cf && cf.length > 0) {
          const isFieldKeyExist = cf.findIndex(
            c => c.fieldKey === customLinkItem.fieldKey
          )
          if (isFieldKeyExist === -1) {
            this.customValueLinkage[elementId].customFields.push(customLinkItem)
          }
        }
      } else {
        const customFields = []
        customFields.push(customLinkItem)
        this.customValueLinkage[elementId] = {
          type,
          customFields,
        }
      }
      this.syncContactIdInLinkedCustomValues()
      this.createRedoStack(true)
    },
    updateCustomValueLinkageItem(
      elementId: string,
      addCustomLinkItem: ICustomFieldsLinkItem,
      removeCustomLinkItem: ICustomFieldsLinkItem
    ) {
      if (this.customValueLinkage[elementId]) {
        const customFields = this.customValueLinkage[elementId].customFields
        const index = customFields.findIndex(
          item => item.fieldKey === removeCustomLinkItem?.fieldKey
        )
        if (index !== -1) {
          customFields.splice(index, 1, addCustomLinkItem)
        }
      }
    },
    setCustomValueLinkage(
      customValueLinkage: Record<string, ICustomFieldsLink>
    ) {
      this.customValueLinkage = customValueLinkage
      if (!this.isDocumentEditable) return
      this.syncContactIdInLinkedCustomValues()
      this.createRedoStack(true)
    },
    deleteCustomValueLinkage(elementId: string, fieldKey: string) {
      if (elementId && this.customValueLinkage[elementId] && fieldKey) {
        const customFields = this.customValueLinkage[elementId].customFields
        if (customFields && customFields.length > 0) {
          const index = customFields.findIndex(c => c.fieldKey === fieldKey)
          if (index > -1) {
            customFields.splice(index, 1)
          }
        }
        if (this.customValueLinkage[elementId].customFields.length === 0) {
          delete this.customValueLinkage[elementId]
        }
        this.createRedoStack(true)
      }
    },
    getCustomValueLinkage(elementId: string) {
      return this.customValueLinkage[elementId]?.customFields || []
    },
    getAllLinkedDocumentVariables() {
      return Object.values(this.customValueLinkage)
        .map(linkage => linkage.customFields)
        .flat()
        .filter(
          field =>
            field &&
            field?.objectType === ObjectForLinkedField.DOCUMENT_VARIABLE
        )
        .map(field => {
          return {
            fieldKey: field.fieldKey,
            value: field.value,
            type: DOCUMENT_LINKED_VARIABLE,
          }
        })
    },

    addElementToExistingGroup(
      elementInfo: { pageId: string; elementId: string },
      groupId: string
    ) {
      this.groups?.forEach(g => {
        if (g.id === groupId) {
          g.children.push(elementInfo)
        }
      })
    },
    addElementsToExistingGroup(
      elementInfo: { pageId: string; elementId: string }[],
      groupId: string
    ) {
      this.groups?.forEach(g => {
        if (g.id === groupId) {
          const cgMap = g.children.map(({ elementId }) => elementId)
          elementInfo.forEach(({ elementId, pageId }) => {
            if (!cgMap.includes(elementId)) {
              g.children.push({ elementId, pageId })
            }
          })
        }
      })
    },
    getElementGroup(elementId: string) {
      return this.groups.find(g => {
        const cgChild = g.children.map((child: any) => child.elementId)
        if (cgChild.includes(elementId)) {
          return g
        }
      })
    },
    updateOneGroup(
      groupId: string,
      children: { pageId: string; elementId: string }[],
      groupType?: string
    ) {
      if (groupId) {
        this.groups?.forEach(g => {
          if (g.id === groupId) {
            g.children = children
            if (groupType) {
              g.component.options.groupType = groupType
            }
          }
        })
      }
    },
    updateGroupOption(params: IUpdateGroupOption) {
      if (!this.isDocumentEditable) return
      const { id, value, key } = params
      const groupIndex = this.groups.findIndex(g => g.id === id)
      if (groupIndex > -1)
        this.groups[groupIndex].component.options[key] = value
      this.createRedoStack()
      return
    },
    updateFocusedElement(element?: IElement) {
      if (!this.isDocumentEditable) return
      this.focusedElement = element
    },
    changeHoverState(state: boolean) {
      if (!this.isDocumentEditable) return
      this.hover = state
    },
    deletePage(pageId: string) {
      if (!this.isDocumentEditable) return
      const pageIndex = this.pages.findIndex(page => page.id === pageId)
      if (pageIndex > -1) {
        this.pages.splice(pageIndex, 1)
      }
      this.syncGroup()
      this.syncCustomFieldsForLinkage()
      this.createRedoStack()
    },
    duplicatePage(pageId: string) {
      if (!this.isDocumentEditable) return

      const pageIndex = this.pages.findIndex(page => page.id === pageId)
      if (pageIndex === -1) return

      const page = this.pages[pageIndex]
      const { children, ...newPageKeys } = cloneDeep(page)

      const generateNewChildren = (children: IElement[]): IElement[] =>
        children?.map(child => ({
          ...child,
          id: generateElementId(),
          children: generateNewChildren(child.children),
        }))

      const newChildren = generateNewChildren(children)

      const newPage = {
        ...newPageKeys,
        children: newChildren,
        id: generateElementId(),
      }

      this.pages.splice(pageIndex + 1, 0, newPage)
      this.createRedoStack()
    },
    deleteElement(elementId: string) {
      if (!this.isDocumentEditable) return

      // Function to recursively search and delete an element inside nested children
      const deleteNestedElement = (children: any[]) => {
        const elementIndex = children.findIndex(
          child => child?.id === elementId
        )
        if (elementIndex !== -1) {
          children.splice(elementIndex, 1)
          return true // Element found and deleted
        }

        // Check if any child contains nested children and search recursively
        for (const child of children) {
          if (child?.children?.length) {
            const isDeleted = deleteNestedElement(child.children)
            if (isDeleted) {
              // After deletion, check if the child should be removed
              if (child.children.length === 0) {
                const childIndex = children.indexOf(child)
                children.splice(childIndex, 1)
              }
              return true
            }
          }
        }

        return false
      }

      // Iterate over pages to find and delete the element
      const pageIndex = this.pages.findIndex(page =>
        page.children?.some(
          element =>
            element.id === elementId ||
            deleteNestedElement(element.children || [])
        )
      )

      if (pageIndex > -1) {
        const page = this.pages[pageIndex]

        // Try to delete the element at the top level first
        const elementIndex = page.children?.findIndex(
          element => element?.id === elementId
        )

        if (elementIndex !== undefined && elementIndex > -1) {
          const deletedElement = page.children[elementIndex]
          page.children.splice(elementIndex, 1)

          // Check if the deleted element was a COLUMN
          if (deletedElement.type === ELEMENTS_LOOKUP.COLUMN) {
            // If it was a column, check if the parent (row) should be deleted
            const parentRow = page.children.find(child =>
              child.children?.includes(deletedElement)
            )
            if (parentRow && parentRow.children.length === 0) {
              const rowIndex = page.children.indexOf(parentRow)
              page.children.splice(rowIndex, 1)
            }
          }
        } else {
          // If not found at the top level, try to delete in nested elements
          page.children.forEach(child => {
            if (child.children?.length) {
              deleteNestedElement(child.children)
            }
          })
        }

        // Clean up empty columns and rows, and replace row -> column -> element with the element directly
        page.children = page.children.flatMap(child => {
          // If child is a COLUMN and has no children, remove it
          if (
            child.type === ELEMENTS_LOOKUP.COLUMN &&
            child.children.length === 0
          ) {
            return []
          }

          // If child is a ROW with only one column, and that column has only one child
          if (
            child.type === ELEMENTS_LOOKUP.ROW &&
            child.children.length === 1 &&
            child.children[0].type === ELEMENTS_LOOKUP.COLUMN &&
            child.children[0].children.length === 1
          ) {
            // Replace the row with the element inside the column
            return [child.children[0].children[0]]
          }

          // If child is a ROW and has no children, remove it
          if (
            child.type === ELEMENTS_LOOKUP.ROW &&
            child.children.length === 0
          ) {
            return []
          }

          return child // Keep this child
        })
      }

      this.createRedoStack()
    },
    deleteElementFromPage(elementId: string, pageId: string) {
      if (!this.isDocumentEditable) return
      const pageIndex = this.pages.findIndex(page => pageId === page.id)
      if (pageIndex > -1) {
        const page = this.pages[pageIndex]
        const elementIndex = page.children?.findIndex(
          element => element.id === elementId
        )
        if (elementIndex !== undefined && elementIndex > -1) {
          page.children?.splice(elementIndex, 1)
        }
      }
      this.createRedoStack()
    },
    cloneElement(elementId: string) {
      if (!this.isDocumentEditable) return
      if (!elementId) return
      let newElement: any
      const pageIndex = this.pages.findIndex(page =>
        page.children?.some(element => {
          if (element.type === ELEMENTS_LOOKUP.ROW) {
            return element.children?.some(child => {
              if (child.type === ELEMENTS_LOOKUP.COLUMN) {
                return child.children?.some(c => {
                  if (c.id === elementId) return true
                  if (c.children && c.children.length) {
                    return c.children.some(cc => cc.id === elementId)
                  }
                })
              }
              return child.id === elementId
            })
          } else {
            if (element.id === elementId) return true
            if (element.children && element.children.length) {
              return element.children.some(child => child.id === elementId)
            }
          }
        })
      )
      if (pageIndex > -1) {
        const page = this.pages[pageIndex]

        const createNestedClone = (element: IElement) => {
          let clone: IElement = {
            ...cloneDeep(element),
            id: generateElementId(),
            children: [],
            // children: element.children?.map(createNestedClone) || [],
          }
          if (element.type === ELEMENTS_LOOKUP.TEXT_FIELD) {
            clone = {
              ...clone,
              component: {
                ...clone.component,
                options: {
                  ...clone.component.options,
                  fieldId: `text_field_${getNextCount()}`,
                },
              },
            }
          }

          if (element.component?.isDraggable) {
            let position = {
              top: (element?.responsiveStyles?.large?.position?.top || 0) + 10,
              left:
                (element?.responsiveStyles?.large?.position?.left || 0) + 10,
              bottom:
                (element?.responsiveStyles?.large?.position?.bottom || 0) - 10,
              right:
                (element?.responsiveStyles?.large?.position?.right || 0) - 10,
            }

            if (element.type === ELEMENTS_LOOKUP.CHECKBOX) {
              const group = this.getElementGroup(elementId)
              if (group) {
                const pos = getNewPositionForElement(
                  elementId,
                  pageIndex,
                  PositionTypeEnum.BOTTOM_TOP
                )
                if (pos) {
                  position = pos
                }
              }
            }

            clone = {
              ...clone,
              responsiveStyles: {
                ...clone.responsiveStyles,
                large: {
                  ...clone.responsiveStyles.large,
                  position: {
                    ...clone.responsiveStyles.large.position,
                    ...position,
                  },
                },
              },
            }
          }
          return clone
        }
        const findAndCloneElement = (elements: any[]): boolean => {
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i]

            if (element.id === elementId) {
              newElement = createNestedClone(element)

              elements.splice(i + 1, 0, newElement)
              this.updateActiveElement(newElement)
              return true
            }

            if (element.children) {
              const found = findAndCloneElement(element.children)
              if (found) return true
            }
          }
          return false
        }

        findAndCloneElement(page.children)
      }

      this.createRedoStack()
      return newElement
    },
    clearEditorInstance() {
      if (!this.isDocumentEditable) return
      this.editor = undefined
    },
    updateEditorInstance(editor: typeof Editor) {
      if (!this.isDocumentEditable) return
      this.editor = editor as typeof Editor
    },
    updateActiveElement(element: IElement | null) {
      if (!this.isDocumentEditable) return
      if (element?.id) {
        this.activeElementId = cloneDeep(element).id
        if (
          element &&
          ![ELEMENTS_LOOKUP.TEXT, ELEMENTS_LOOKUP.TABLE].includes(element.type)
        )
          this.clearEditorInstance()
        // this.createRedoStack()
      }
      if (!element) {
        this.activeElementId = null
      }
    },
    splitPage(pageId: string, index: number) {
      const pageIndex = this.pages.findIndex(x => x.id === pageId)
      if (pageIndex > -1) {
        const page = this.pages[pageIndex]
        const newPage = createElement({ type: ELEMENTS_LOOKUP.PAGE })
        if (newPage && page.children?.length) {
          const elements = page.children?.splice(index)
          if (elements?.length) {
            newPage.children = elements
            this.pages.splice(pageIndex + 1, 0, newPage)
          }
        } else if (newPage) {
          this.pages.splice(pageIndex + 1, 0, newPage)
        }
      }
    },
    addElement(
      element: IElement,
      pageId: string,
      rowIndex = -1, // Defaults to -1 for top-level insertion
      colIndex = -1, // Defaults to -1 for adding directly to row, 0+ for column
      rowColIndex = -1 // Index of children inside column, 0+ to specify position
    ) {
      if (!this.isDocumentEditable) return

      const pageIndex = this.pages.findIndex(x => x.id === pageId)
      if (pageIndex > -1) {
        const page = this.pages[pageIndex]
        if (colIndex > -1) {
          const row = page.children[rowIndex]
          if (row?.type === ELEMENTS_LOOKUP.ROW) {
            const newColumnElement = createElement({
              type: ELEMENTS_LOOKUP.COLUMN,
            })
            if (rowColIndex > -1) {
              this.pages[pageIndex]?.children[rowIndex]?.children[
                colIndex
              ]?.children?.splice(rowColIndex, 0, element as IElement)
            } else {
              newColumnElement?.children.push(element)
              this.pages[pageIndex]?.children[rowIndex]?.children?.splice(
                colIndex,
                0,
                newColumnElement as IElement
              )
              this.pages[pageIndex]?.children[rowIndex]?.children?.forEach(
                (child: IElement) => {
                  child.responsiveStyles.large.width = ''
                }
              )
            }
          } else {
            const columnForExistingElement = createElement({
              type: ELEMENTS_LOOKUP.COLUMN,
            })
            const columnForNewElement = createElement({
              type: ELEMENTS_LOOKUP.COLUMN,
            })
            const newRowElement = createElement({ type: ELEMENTS_LOOKUP.ROW })
            columnForExistingElement?.children.push(row)
            columnForNewElement?.children.push(element)
            newRowElement?.children.push(columnForExistingElement as IElement)
            newRowElement?.children.splice(
              colIndex,
              0,
              columnForNewElement as IElement
            )
            page.children.splice(rowIndex, 1, newRowElement as IElement)
          }
        } else {
          page.children.splice(rowIndex, 0, element)
          if (element.type === ELEMENTS_LOOKUP.PAGE_BREAK) {
            this.splitPage(pageId, rowIndex)
          }
        }
        this.updateActiveElement(element)
        this.createRedoStack()
      }
    },
    moveElementInArray<T>(array: T[], fromIndex: number, toIndex: number): T[] {
      if (
        fromIndex < 0 ||
        fromIndex >= array.length ||
        toIndex < 0 ||
        toIndex >= array.length ||
        fromIndex === toIndex
      ) {
        // Invalid indices or no movement needed
        return array
      }

      const newArray: T[] = [...array]
      const [movedElement] = newArray.splice(fromIndex, 1)
      newArray.splice(toIndex, 0, movedElement)

      return newArray
    },
    moveElement(
      element: IElement,
      pageId: string,
      newIndex: number,
      oldIndex: number
    ) {
      if (!this.isDocumentEditable) return
      const pageIndex = this.pages.findIndex(x => x.id === pageId)
      if (pageIndex > -1) {
        const elements = this.pages[pageIndex].children
        const elementsWithoutDraggable = elements.filter(
          child => !child.component.isDraggable
        )
        const draggableElements = elements.filter(
          child => child.component.isDraggable
        )
        this.pages[pageIndex].children = [
          ...this.moveElementInArray(
            cloneDeep(elementsWithoutDraggable),
            oldIndex,
            newIndex
          ),
          ...draggableElements,
        ]
      }
      this.updateActiveElement(element)
      if (
        ![ELEMENTS_LOOKUP.TEXT, ELEMENTS_LOOKUP.TABLE].includes(element.type)
      ) {
        this.clearEditorInstance()
      }
      this.createRedoStack()
    },
    addPage(index = 0) {
      if (!this.isDocumentEditable) return
      const pageElement = createElement({ type: ELEMENTS_LOOKUP.PAGE })
      if (pageElement) this.pages.splice(index + 1, 0, pageElement)
      this.clearEditorInstance()
      this.createRedoStack()
    },
    updateElementOption(params: IUpdateElementOption) {
      if (!this.isDocumentEditable) return

      const { id, value, key, type } = params

      if (type === ELEMENTS_LOOKUP.PAGE) {
        const pageIndex = this.pages.findIndex(x => x.id === id)
        if (pageIndex > -1) {
          this.pages[pageIndex].component.options[key] = value
        }
        this.createRedoStack()
        return
      }

      const updateNestedElement = (elements: any[]) => {
        for (let index = 0; index < elements.length; index++) {
          const element = elements[index]
          if (element?.id === id) {
            element.component.options[key] = value
            return true
          }
          if (element?.children?.length) {
            if (updateNestedElement(element.children)) return true
          }
        }
        return false
      }

      // Iterate over all pages and their nested children to find and update the element
      for (let i = 0; i < this.pages.length; i++) {
        if (updateNestedElement(this.pages[i].children)) {
          this.createRedoStack()
          return
        }
      }
    },
    updateElementStyle(params: IUpdateElementOption) {
      if (!this.isDocumentEditable) return
      if (!this.isDocumentEditable) return
      const { id, value, key, type } = params
      if (type === ELEMENTS_LOOKUP.PAGE) {
        const pageIndex = this.pages.findIndex(x => x.id === id)
        if (pageIndex > -1) {
          this.pages[pageIndex].responsiveStyles.large[key] = cloneDeep(value)
        }
        this.createRedoStack()
        return
      }
      const updateStyle = (children: IElement[]) => {
        for (let i = 0; i < children.length; i++) {
          if (children[i]?.id === id) {
            children[i].responsiveStyles.large[key] = cloneDeep(value)
            return true
          }
          if (children[i]?.children?.length) {
            const found = updateStyle(children[i].children)
            if (found) return true
          }
        }
        return false
      }

      // Iterate over all pages and their nested children to find and update the element's style
      for (const page of this.pages) {
        if (updateStyle(page?.children)) {
          this.createRedoStack()
          return
        }
      }
    },
    updateGroupStyle(params: IUpdateElementOption) {
      if (!this.isDocumentEditable) return
      const { id, value, key, type } = params
      if (type === GROUP_LOOKUP.GROUP_FIELDS) {
        const groupIndex = this.groups.findIndex(g => g.id === id)
        if (groupIndex > -1)
          this.groups[groupIndex].responsiveStyles.large[
            key as keyof IResponsiveStylesOptions
          ] = value
        this.createRedoStack()
      }
      return
    },
    assignRecipientToEverySignatureElement(recipient: IRecipient) {
      if (!this.isDocumentEditable) return
      if (!recipient.id) return
      this.pages = this.pages.map(page => {
        return Object.assign(page, {
          children: page.children?.map(child => {
            if (child.type === ELEMENTS_LOOKUP.SIGNATURE) {
              return {
                ...child,
                component: {
                  ...child.component,
                  options: {
                    ...child.component.options,
                    [ELEMENTS_META.RECIPIENT]: recipient.id,
                  },
                },
              }
            }
            return child
          }),
        })
      })
      this.createRedoStack()
    },
    autoAssignIfRecipientIdIsNotPresentInRecipientList() {
      if (!this.isDocumentEditable) return

      const appStore = useAppStore()
      const primaryRecipient = appStore.document?.recipients.find(
        r => r.isPrimary
      )
      if (!primaryRecipient) return

      const { RECIPIENT, ENTITY_NAME } = ELEMENTS_META

      const assignRecipient = (options: any) => {
        const recipientExist = appStore.document?.recipients.some(
          r =>
            r.id === options[RECIPIENT] && r.entityName === options[ENTITY_NAME]
        )

        if (!recipientExist || !options[RECIPIENT] || !options[ENTITY_NAME]) {
          return {
            ...options,
            [RECIPIENT]: primaryRecipient.id,
            [ENTITY_NAME]: primaryRecipient.entityName,
          }
        }
        return options
      }

      const autoAssign = (children: IElement[]): IElement[] => {
        return children.map(child => {
          if (assignableFields.has(child?.type)) {
            child.component.options = assignRecipient(child.component.options)
          }
          if (child?.children && child?.children?.length) {
            child.children = autoAssign(child.children)
          }
          return child
        })
      }

      this.pages.forEach(page => {
        page.children = autoAssign(page.children)
      })
      this.groups.forEach(group => {
        group.component.options = assignRecipient(group.component.options)
        group.children = group.children.map(child => {
          if (assignableFields.has(child?.type)) {
            if (child && child?.component) {
              child.component.options = assignRecipient(child.component.options)
            }
          }
          return child
        })
      })

      this.createRedoStack()
    },
    getAllFillableElements() {
      return flatChildrens(this.pages).filter(element =>
        assignableFields.has(element.type)
      )
    },
    syncContactIdInLinkedCustomValues() {
      const customValueLinkage = this.customValueLinkage
      const fillableElementMap = new Map<string, any>()
      flatChildrens(this.pages).forEach(element => {
        if (fillableFields.has(element.type)) {
          fillableElementMap.set(element.id, element)
        }
      })
      Object.keys(customValueLinkage).forEach(elementId => {
        const element = fillableElementMap.get(elementId)
        if (element && element?.component?.options?.recipient) {
          customValueLinkage[elementId].contactId =
            element.component.options.recipient
        }
      })
    },
    getContactCustomValueLinkMapping() {
      const customValueLinkage = this.customValueLinkage
      const data = Object.keys(customValueLinkage).reduce((acc, elementId) => {
        const customFields = customValueLinkage[elementId].customFields
        const contactId = customValueLinkage[elementId].contactId
        if (contactId) {
          acc[contactId] = acc[contactId]
            ? [...acc[contactId], ...customFields]
            : [...customFields]
        }
        return acc
      }, {} as Record<string, any>)
      return data
    },
    setActiveElementId(id: string) {
      this.activeElementId = id
    },
    syncRecipient() {
      const appStore = useAppStore()
      const { updateDocumentOption } = useElementOption()
      const recipients = appStore.document?.recipients
      const fillableElements = this.getAllFillableElements()

      // we are removing the fillable elements which is part of group.
      const fillableElementsNotPartOfAnyGroup =
        removeFillableElementsWhichIsPartOfGroup(fillableElements, this.groups)

      // merge groups and fillable elements
      const allAssignableFieldsWithGroups = [
        ...this.groups,
        ...fillableElementsNotPartOfAnyGroup,
      ]

      const allRecipient = removeUserFromRecipientIfElementIsNotAssigned(
        allAssignableFieldsWithGroups,
        recipients
      )

      const allRecipientsWithRoles = updateRecipientRolesIfElementIsAssigned(
        allAssignableFieldsWithGroups,
        allRecipient
      )
      updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipientsWithRoles)
    },
    syncGroupRecipient() {
      this.groups.forEach(g => {
        const elementIds = g.children.map(({ elementId }) => elementId)
        if (g.component.options.recipient && g.component.options.entityName) {
          this.pages.forEach(page => {
            page.children?.forEach(element => {
              if (elementIds.includes(element.id)) {
                element.component.options.recipient =
                  g.component.options.recipient
                element.component.options.entityName =
                  g.component.options.entityName
              }
            })
          })
        }
      })
    },
    syncCurrency(currencyCode: string) {
      if (!this.isDocumentEditable) return
      this.pages = this.pages.map(page => {
        return Object.assign(page, {
          children: page.children?.map(child => {
            if (child.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
              return {
                ...child,
                component: {
                  ...child.component,
                  options: {
                    ...child.component.options,
                    [ELEMENTS_META.CURRENCY_CODE]: currencyCode,
                    [ELEMENTS_META.LINE_ITEMS]:
                      child.component.options.lineItems.map(
                        (lineItem: any) => ({
                          ...lineItem,
                          currency: currencyCode,
                        })
                      ),
                  },
                },
              }
            }
            if (child.type === ELEMENTS_LOOKUP.ROW) {
              return {
                ...child,
                children: child.children?.map(column => {
                  return {
                    ...column,
                    children: column.children?.map(el => {
                      if (el.type === ELEMENTS_LOOKUP.PRODUCT_LIST) {
                        return {
                          ...el,
                          component: {
                            ...el.component,
                            options: {
                              ...el.component.options,
                              [ELEMENTS_META.CURRENCY_CODE]: currencyCode,
                              [ELEMENTS_META.LINE_ITEMS]:
                                el.component.options.lineItems.map(
                                  (lineItem: any) => ({
                                    ...lineItem,
                                    currency: currencyCode,
                                  })
                                ),
                            },
                          },
                        }
                      } else {
                        return el
                      }
                    }),
                  }
                }),
              }
            }
            return child
          }),
        })
      })
    },
    syncGroup() {
      // get all the element ids of the all the pages.
      const elementIds = this.pages.reduce((acc, page) => {
        page.children.forEach(element => {
          if (element && element?.id) acc.push(element.id)
        })
        return acc
      }, [])

      // filtering & updating the group elements, if the element is available in the pages.
      this.groups.forEach(g => {
        g.children = g.children.filter(({ elementId }) =>
          elementIds.includes(elementId)
        )
      })

      // deleting the group if its having child length of 1 or less.
      this.groups = this.groups.filter(g => g.children?.length > 1)
    },
    getFillableFields() {
      const fillableFields = new Set([
        ELEMENTS_LOOKUP.TEXT_FIELD,
        ELEMENTS_LOOKUP.DATE_FIELD,
        ELEMENTS_LOOKUP.CHECKBOX,
      ])
      return flatChildrens(this.pages)
        .filter(child => fillableFields.has(child.type))
        .map(child => {
          const {
            component: { options: option },
          } = child
          return {
            value: option.text || ('' as string),
            fieldId: option.fieldId as string,
            isRequired: option.required as boolean,
            recipient: option.recipient,
            hasCompleted: option.text ? true : false,
            entityType: option.entityName as RecipientEntityTypeEnum,
            id: child.id,
            type: child.type,
          }
        }) as UpdateSignatureParams['fillableFields']
    },
    getAllProductList() {
      return flatChildrens(this.pages).filter(
        element => element.type === ELEMENTS_LOOKUP.PRODUCT_LIST
      )
    },
    setFlatCustomValues(values: any) {
      this.flatCustomValues = values
    },
    isRecurringProduct() {
      return flatChildrens(this.pages).some(
        child =>
          child.type === ELEMENTS_LOOKUP.PRODUCT_LIST &&
          child?.component?.options?.lineItems?.some(
            (item: LineItem) =>
              item.priceType === PriceTypeEnum.RECURRING &&
              !item?.isSetupFeeItem
          )
      )
    },
    syncCustomFieldsForLinkage() {
      const keys = Object.keys(this.customValueLinkage)
      // if the key is not exist in the page fillable fields then remove the key from the custom value linkage
      const fillableElements = flatChildrens(this.pages).filter(element =>
        fillableFields.has(element.type)
      )

      // iterate over the custom value linkage and check
      // if the key is not exist in the fillable elements then remove the key from the custom value linkage
      keys.forEach(key => {
        const isKeyExist = fillableElements.some(element => element.id === key)
        if (!isKeyExist) {
          delete this.customValueLinkage[key]
        }
      })

      // if the primary recipient is there then only do the below operation.
      // otherwise, cloneFromTheTemplate will have an issue.
      const appStore = useAppStore()
      const primaryContact = appStore?.primaryContact
      if (primaryContact && primaryContact.id) {
        fillableElements.forEach(element => {
          if (this.customValueLinkage[element.id]) {
            const contactId = this.customValueLinkage[element.id].contactId
            if (!contactId) {
              delete this.customValueLinkage[element.id]
            }
            if (contactId && element.component?.options?.recipient) {
              if (
                element.component?.options?.entityName !==
                RecipientEntityTypeEnum.CONTACTS
              ) {
                delete this.customValueLinkage[element.id]
              }
            }
          }
        })
      }
    },
    deleteElementFromNestedChildren(id: string) {
      if (!this.isDocumentEditable) return
      const findAndDelete = children => {
        for (let i = 0; i < children.length; i++) {
          if (children[i]?.id === id) {
            children?.splice(i, 1)
            return true
          }
          if (children[i]?.children && children[i]?.children?.length) {
            const found = findAndDelete(children[i].children)
            if (found) return true
          }
        }
        return false
      }

      this.pages.forEach(page => {
        findAndDelete(page.children)
      })
      this.createRedoStack()
    },
    addElementToElementChildren(parentId: string, newElement: IElement) {
      if (!this.isDocumentEditable) return

      const findAndAdd = children => {
        for (let i = 0; i < children.length; i++) {
          if (children[i].id === parentId) {
            children[i].children = children[i].children || []
            children[i].children.push(newElement)
            return true
          }
          if (children[i].children && children[i].children.length) {
            const found = findAndAdd(children[i].children)
            if (found) return true
          }
        }
        return false
      }

      // Check if parentId belongs to a Page object
      for (const page of this.pages) {
        if (page.id === parentId) {
          page.children = page.children || []
          page.children.push(newElement)
          return
        }

        // Otherwise, search within the children of the Page
        if (findAndAdd(page.children)) {
          return
        }
      }
      this.createRedoStack()
    },
    moveElementById(elementId: string, newParentId: string) {
      let elementToMove: IElement | null = null

      // Function to find and remove the element by id using .filter
      const findAndRemove = (children: IElement[]): IElement[] => {
        return children
          .map(child => {
            if (child.id === elementId) {
              elementToMove = child
              return null // Return null to remove the element
            }
            // Recursively find and remove the element in nested children
            if (child.children && child.children.length > 0) {
              child.children = findAndRemove(child.children).filter(Boolean)
            }
            return child // Keep the child if it's not the one to remove
          })
          .filter(Boolean) as IElement[] // Filter out any nulls
      }

      // First, find and remove the element from its current location
      this.pages.forEach(page => {
        page.children = findAndRemove(page.children)
      })

      // If the element was found, add it to the new parent's children list
      if (elementToMove) {
        const findAndAdd = (children: IElement[]) => {
          for (let i = 0; i < children.length; i++) {
            if (children[i]?.id === newParentId) {
              children[i].children = children[i].children || []
              children[i].children.push(elementToMove)
              return true
            }
            // Recursive search through nested children
            if (children[i]?.children && children[i].children.length > 0) {
              const found = findAndAdd(children[i].children)
              if (found) return true
            }
          }
          return false
        }

        // Check if newParentId belongs to a Page object
        for (const page of this.pages) {
          if (page?.id === newParentId) {
            page.children = page.children || []
            page.children.push(elementToMove)
            return
          }

          // Otherwise, search within the children of the Page
          if (findAndAdd(page.children)) {
            return
          }
        }
      }
    },
    moveFloatingElement(
      elementId: string,
      options: { id: string; type: string; pageId: string }
    ) {
      this.moveElementById(elementId, options.id)
    },
  },
})
