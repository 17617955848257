import { useElementOption } from '@/composable/elementOptions'
import { useAppStore } from '@/store/app'
import { useBuilderStore } from '@/store/builder'
import {
  APP_STORE_META,
  ELEMENTS_META,
  GroupFields,
  GroupFieldsOptions,
  IElement,
  IRecipient,
  RecipientEntityTypeEnum,
  extractRecipientIdAndEntityName,
  getRecipientParamsFromContact,
  useTypography,
} from '@gohighlevel/ghl-proposals-common'
import i18n from '@gohighlevel/ghl-proposals-common/src/locales'
import { computed } from 'vue'

export const useRecipients = () => {
  const builderStore = useBuilderStore()
  const appStore = useAppStore()
  const { toCapitalize } = useTypography()
  const { updateDocumentOption, updateGroupOption, updateOption } =
    useElementOption()
  const recipients = computed(() => appStore.document?.recipients)

  const getCurrantLoggedInUserDetails = () => {
    const contactDetails = getRecipientParamsFromContact(
      appStore.userData,
      RecipientEntityTypeEnum.USERS,
      false
    )

    return {
      ...contactDetails,
      contactName: `${contactDetails.firstName} ${contactDetails.lastName}`,
    }
  }

  const signedByOptions = computed(() => {
    let allRecipients = recipients.value
    const currentUser = getCurrantLoggedInUserDetails()
    const currentUserExist = allRecipients.find(elem => {
      return (
        elem.id === currentUser.id &&
        elem.entityName === RecipientEntityTypeEnum.USERS
      )
    })
    if (!currentUserExist) {
      allRecipients = [...allRecipients, currentUser]
    }
    const recipientList =
      allRecipients.map((contact: IRecipient) => ({
        ...contact,
        label: toCapitalize(
          appStore.userId === contact.id
            ? `${contact?.contactName || ''} ${i18n.global.t('common.you')}`
            : contact?.contactName || `${contact.firstName} ${contact.lastName}`
        ).trim(),
        value: `${contact.id}_${contact.entityName}`,
      })) || []
    return recipientList
  })

  const assignRecipientToGroup = (val: string, groupId: string) => {
    const { recipientId, entityName } = extractRecipientIdAndEntityName(val)
    if (recipientId && entityName) {
      if (
        entityName === RecipientEntityTypeEnum.USERS &&
        appStore.userId === recipientId
      ) {
        const isDuplicateUsers = recipients.value.find((el: any) => {
          return el.id === appStore.userId && el.entityName === entityName
        })
        if (!isDuplicateUsers) {
          const contactDetails = getCurrantLoggedInUserDetails()
          const allRecipients = [...recipients.value, contactDetails]
          updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
        }
      }
      updateGroupOption(ELEMENTS_META.RECIPIENT, recipientId, groupId)
      updateGroupOption(ELEMENTS_META.ENTITY_NAME, entityName, groupId)

      // update group element also.
      const group = builderStore.groups.find(g => g.id === groupId)
      group?.children.forEach(({ elementId }) => {
        const element = builderStore.getElementById(elementId)?.element
        if (element) {
          updateOption(
            ELEMENTS_META.RECIPIENT,
            recipientId,
            element.id,
            element.type
          )
          updateOption(
            ELEMENTS_META.ENTITY_NAME,
            entityName,
            element.id,
            element.type
          )
        }
      })
    }
  }

  const assignRecipientToElement = (val: string, element: IElement) => {
    const id = element.id
    const type = element.type
    const { recipientId, entityName } = extractRecipientIdAndEntityName(val)
    if (recipientId && entityName) {
      if (
        entityName === RecipientEntityTypeEnum.USERS &&
        appStore.userId === recipientId
      ) {
        const isDuplicateUsers = recipients.value.find((el: any) => {
          return el.id === appStore.userId && el.entityName === entityName
        })
        if (!isDuplicateUsers) {
          const contactDetails = getCurrantLoggedInUserDetails()
          const allRecipients = [...recipients.value, contactDetails]
          updateDocumentOption(APP_STORE_META.RECIPIENTS, allRecipients)
        }
      }
    }
    updateOption(ELEMENTS_META.RECIPIENT, recipientId, id, type)
    updateOption(ELEMENTS_META.ENTITY_NAME, entityName, id, type)
  }

  const fetchRecipientForElementOrGroup = (
    element: IElement | GroupFields<GroupFieldsOptions>,
    recipientId?: string,
    entityName?: string
  ) => {
    const assignedRecipient = recipients.value.find((recipient: IRecipient) => {
      if (recipientId && entityName) {
        return (
          recipient.id === recipientId && recipient.entityName === entityName
        )
      } else {
        return (
          recipient.id ===
            element?.component?.options[ELEMENTS_META.RECIPIENT] &&
          recipient.entityName ===
            element?.component?.options[ELEMENTS_META.ENTITY_NAME]
        )
      }
    })
    return assignedRecipient
  }

  const getSelectValue = (
    element: IElement | GroupFields<GroupFieldsOptions>
  ) => {
    if (
      element?.component?.options[ELEMENTS_META.RECIPIENT] &&
      element?.component?.options[ELEMENTS_META.ENTITY_NAME]
    ) {
      return `${element?.component?.options[ELEMENTS_META.RECIPIENT]}_${
        element?.component?.options[ELEMENTS_META.ENTITY_NAME]
      }`
    }
    return null
  }

  return {
    recipients,
    signedByOptions,
    getCurrantLoggedInUserDetails,
    assignRecipientToGroup,
    fetchRecipientForElementOrGroup,
    assignRecipientToElement,
    getSelectValue,
  }
}
