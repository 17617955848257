<script lang="ts" setup>
import {
  UIButton,
  UIForm,
  UIFormItem,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UISelect,
  UIInputNumber,
} from '@gohighlevel/ghl-ui'
import {
  BusinessProduct,
  BusinessProductPrice,
  BusinessProductPriceOption,
  CProductListProps,
  ProductLineEvents,
  PriceTypeEnum,
} from '@gohighlevel/ghl-proposals-common'
import { computed, ref } from 'vue'
import { PlusIcon } from '@gohighlevel/ghl-icons/24/outline'
import { useProductListStore } from '@/store/productListStore'
import { useCurrency } from '@/composition'

const productListStore = useProductListStore()
const { getCurrencySymbol } = useCurrency()

const props = withDefaults(defineProps<CProductListProps>(), {
  show: false,
  loading: false,
})
const productsPriceList = ref<BusinessProductPriceOption[]>([])
const formValue = ref({
  productId: '',
  priceId: '',
  setupFee: 0,
  priceType: '',
})
const currencySymbol = ref()
const currencyCode = ref()

const products = computed(() => {
  return props.productList
    .map((product: BusinessProduct) => {
      if (!product.prices) return
      return {
        ...product,
        label: product.name,
        value: product._id,
        disabled: isProductDisabled(product),
        prices: product?.prices.map((price: BusinessProductPrice) => {
          return {
            label: price?.name,
            value: price?._id,
            disabled: isPriceDisabled(price),
            ...price,
          }
        }),
      }
    })
    .filter(Boolean)
})

const productOptions = computed(() => {
  return products.value.map((product: any) => {
    return {
      label: product.name,
      value: product._id,
      disabled: product.disabled,
    }
  })
})
const resetFormValue = () => {
  formValue.value = {
    productId: '',
    priceId: '',
    setupFee: 0,
    priceType: '',
  }
  productsPriceList.value = []
}

const handleSelectProduct = (value: string) => {
  formValue.value.productId = value
  formValue.value.priceId = ''
  productsPriceList.value = (props.productList?.find(
    item => item._id === formValue.value.productId
  )?.prices || []) as BusinessProductPriceOption[]
  if (productsPriceList.value.length >= 1) {
    formValue.value.priceId = productsPriceList.value[0]._id
    handleSelectProductPrice(formValue.value.priceId)
  }
}

const handleSelectProductPrice = (priceId: string) => {
  const price = productsPriceList.value.find(
    priceItem => priceItem._id === priceId
  )
  if (price) {
    currencySymbol.value = getCurrencySymbol(price.currency)
    currencyCode.value = price.currency
    formValue.value.priceType = price.type
    formValue.value.setupFee = price?.setupFee || 0
  }
}

function isProductDisabled(product: BusinessProduct) {
  if (productListStore.currency) {
    const showProduct = product?.prices?.some(
      (item: BusinessProductPrice) =>
        item.currency === productListStore.currency
    )
    return !showProduct
  }

  return false
}

function isPriceDisabled(price: BusinessProductPrice) {
  if (!productListStore.currency) {
    return false
  }
  return price.currency != productListStore.currency
}
</script>

<template>
  <UIModal :show="props.show">
    <template #header>
      <div class="py-2">
        <UIButton
          id="add-new-item-btn"
          class="border-0 w-full px-2 py-7 text-primary-700 rounded-lg bg-primary-50"
          type="tertiary"
          @click="() => $emit(ProductLineEvents.OPEN_ADD_PRODUCT_MODAL)"
        >
          <PlusIcon class="w-5 h-5 mr-2" />
          {{ $t('common.newItem') }}
        </UIButton>
      </div>
    </template>
    <UIModalContent>
      <UIForm id="add-product-form">
        <UIFormItem
          :label="$t('common.selectProduct')"
          path="productId"
          required
        >
          <UISelect
            id="product-item"
            :loading="props.loading"
            :options="productOptions"
            :value="formValue.productId"
            :placeholder="$t('common.selectProduct')"
            @change="handleSelectProduct"
          />
        </UIFormItem>
        <UIFormItem :label="$t('common.price')" path="priceId" required>
          <UISelect
            id="products-price-item"
            v-model:value="formValue.priceId"
            :loading="props.loading"
            :options="productsPriceList"
            placeholder="Select a price"
            @change="handleSelectProductPrice"
          />
        </UIFormItem>

        <UIFormItem
          v-if="formValue.priceType === PriceTypeEnum.RECURRING"
          :label="$t('common.setupFee')"
          path="setupFee"
        >
          <div class="w-full">
            <UIInputNumber
              id="product-price-setupFee"
              v-model="formValue.setupFee"
              placeholder="0"
              :showButton="false"
              :precision="2"
              :min="0"
              size="large"
              class="w-full"
            >
              <template #prefix>{{ currencySymbol }}</template>
              <template #suffix>{{ currencyCode }}</template>
            </UIInputNumber>
          </div>
        </UIFormItem>
      </UIForm>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        id="add-new-product-modal-footer"
        :negativeText="$t('common.cancel')"
        :positiveText="$t('common.addItem')"
        @negativeClick="
          () => {
            $emit(ProductLineEvents.CLOSE_ADD_ITEM_MODAL)
            resetFormValue()
          }
        "
        @positiveClick="
          () => {
            $emit(ProductLineEvents.ADD_LINE_ITEM_TO_LIST, formValue)
            resetFormValue()
          }
        "
      ></UIModalFooter>
    </template>
  </UIModal>
</template>

<style>
.n-modal-container {
  z-index: 2020 !important;
}
</style>
